// import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@components/ui/dropdown-menu';
import { BsCheck } from 'react-icons/bs';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@components/ui/dialog';
import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import { trackEvent } from '@analytics/trackEvent';
import { Button } from '@components/ui/button';
import { DialogClose } from '@radix-ui/react-dialog';
import Image from 'next/image';
import { X } from 'lucide-react';
import { FaLightbulb } from 'react-icons/fa';

const GOOD_PHOTOS = [
  'In focus and facing camera',
  'Ideal face distance',
  'Centered and well-lit',
  'Solo portrait',
  'High resolution'
];

const BAD_PHOTOS = [
  {
    id: 'black-and-white',
    url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/07ade852-5b4b-44d6-571a-d52081b5b000/768',
    label: 'Not black and white'
  },
  {
    id: 'same-shoot',
    url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3a1b2a09-44d7-4de5-b6c5-51c2b17c8d00/public',
    label: 'Not same shoot'
  },
  {
    id: 'group-photo',
    url: 'https://storage.prompt-hunt.workers.dev/multiple-people.jpg',
    label: 'No group photos'
  },
  {
    id: 'sunglasses',
    url: 'https://storage.prompt-hunt.workers.dev/sunglasses.jpg',
    label: 'No sunglasses'
  },
  {
    id: 'too-close',
    url: 'https://storage.prompt-hunt.workers.dev/too-close.jpg',
    label: 'Not too close'
  },
  {
    id: 'not-looking-at-camera',
    url: 'https://storage.prompt-hunt.workers.dev/not-looking-at-camera.jpg',
    label: 'Must face camera'
  },
  {
    id: 'blurry',
    url: 'https://storage.prompt-hunt.workers.dev/blurry.jpg',
    label: 'No blurry images'
  },
  {
    id: 'too-dark',
    url: 'https://storage.prompt-hunt.workers.dev/low-light.jpg',
    label: 'No dark images'
  },
  {
    id: 'pixelated',
    url: 'https://storage.prompt-hunt.workers.dev/pixelated.jpg',
    label: 'No pixelated images'
  },
  {
    id: 'not-centered',
    url: 'https://storage.prompt-hunt.workers.dev/not-centered.jpg',
    label: 'Must be centered'
  },
  {
    id: 'face-covered',
    url: 'https://storage.prompt-hunt.workers.dev/face-covered.jpg',
    label: 'No covered faces'
  },
  {
    id: 'silly-faces',
    url: 'https://storage.prompt-hunt.workers.dev/silly.jpg',
    label: 'No silly faces'
  }
];

const handleViewGuidelines = () => {
  trackEvent(AnalyticsEvents.trainGuidelinesViewed);
};

const GoodPhotos = () => {
  return (
    <div className="bg-success/10 p-4 mb-10 rounded-lg border-success/30 border-[1px]">
      <div className="mb-4 text-xl font-medium">Example of a good photo</div>
      <div className="grid grid-cols-3 gap-x-4 gap-y-8 items-center">
        <Image
          className="rounded-md pointer-events-none object-cover"
          src="https://storage.prompt-hunt.workers.dev/great.jpg"
          width={512}
          height={512}
          alt={'Great photo'}
        />
        <div className="col-span-2">
          <ul className="list none max-w-md space-y-1 text-primary text-sm list-inside">
            {GOOD_PHOTOS.map(item => (
              <li key={item} className="flex items-center">
                <BsCheck className="w-3.5 h-3.5 mr-2 text-success flex-shrink-0" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const BadPhotos = () => {
  return (
    <div className="mb-10">
      <div className="mb-4 text-xl font-medium">Examples of bad photos</div>
      <ul role="list" className="grid grid-cols-5 gap-x-4 gap-y-8">
        {BAD_PHOTOS.map(item => (
          <li key={item.id} className="relative">
            <div className="group aspect-square block w-full rounded-lg relative">
              <X
                opacity={1}
                strokeWidth={1}
                className="absolute h-full w-full text-red-500 top-0 left-0 right-0 bottom-0 m-auto"
              />
              <Image
                src={item.url}
                width={512}
                height={512}
                className="rounded-md pointer-events-none object-cover mb-2"
                alt={item.label}
              />
            </div>
            <div className="flex flex-row gap-2 justify-center">
              <p className="text-xs text-red-500 dark:text-red-300 text-center">{item.label}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function Guidelines({ defaultOpen = false }) {
  return (
    <Dialog defaultOpen={defaultOpen}>
      <DialogTrigger onClick={handleViewGuidelines} className="">
        Explore tips and guidelines
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] overflow-auto">
        <DialogHeader className="mb-0">
          <DialogTitle>
            <div className="hidden flex w-full justify-center md:justify-start mb-4 pt-10">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-accent">
                <FaLightbulb className="h-6 w-6 text-accent-foreground" aria-hidden="true" />
              </div>
            </div>
            <h1 className="text-4xl mb-0">Tips & Guidelines 💡 </h1>
          </DialogTitle>
          <DialogDescription>
            <p className="text-base mb-6 leading-snug">
              We want to make sure your photos are the best they can be. Here are some guidelines and tips to help you
              generate the best photos possible.
            </p>
          </DialogDescription>
        </DialogHeader>
        <div className="flex-grow pb-10">
          <BadPhotos />
          <GoodPhotos />
        </div>
        <DialogFooter className="">
          <DialogClose className="w-full bg-muted pt-1">
            <Button className="w-full" variant={'default'}>
              Got it - thanks!
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
